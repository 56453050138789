var render = function render(){
  var _vm$activeContract$ra;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "m-2"
  }, [_c('FormGroup', {
    staticClass: "mb-1",
    attrs: {
      "id": "date",
      "label": "Date",
      "description": "Date above is in your local timezone",
      "type": "dateTimePicker",
      "config": {
        utc: false
      },
      "error": _vm.validationErrors.submittedAt
    },
    model: {
      value: _vm.form.submittedAt,
      callback: function ($$v) {
        _vm.$set(_vm.form, "submittedAt", $$v);
      },
      expression: "form.submittedAt"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "value",
      "label": "Value",
      "type": "text",
      "error": _vm.validationErrors.value
    },
    model: {
      value: _vm.form.value,
      callback: function ($$v) {
        _vm.$set(_vm.form, "value", $$v);
      },
      expression: "form.value"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "type",
      "label": "Type",
      "type": "select",
      "options": _vm.typeOptions,
      "error": _vm.validationErrors.type
    },
    model: {
      value: _vm.form.type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }), _vm.registerOptions.length > 1 ? _c('FormGroup', {
    attrs: {
      "id": "registerId",
      "label": "Register",
      "type": "select",
      "options": _vm.registerOptions,
      "error": _vm.validationErrors.registerId
    },
    model: {
      value: _vm.form.registerId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "registerId", $$v);
      },
      expression: "form.registerId"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "mb-3 bg-light p-3 rounded-md"
  }, [_c('div', {
    staticClass: "font-w600 mb-1"
  }, [_vm._v("Meter Overrides")]), _c('div', {
    staticClass: "font-size-sm font-w600 text-muted mb-3"
  }, [_vm._v(" By default the meter's units and factors will be used. Only override these if you are aware of the implications this may have. ")]), _c('div', {
    staticClass: "row mb-2"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('FormGroup', {
    staticClass: "mb-0",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "id": "units",
      "placeholder": `Default (${_vm.selectedAccount.meterUnits})`,
      "label": "Units",
      "type": "select",
      "options": _vm.unitOptions,
      "error": _vm.validationErrors.units,
      "config": {
        allowClear: true
      }
    },
    model: {
      value: _vm.form.units,
      callback: function ($$v) {
        _vm.$set(_vm.form, "units", $$v);
      },
      expression: "form.units"
    }
  })], 1), _c('div', {
    staticClass: "col-md-5"
  }, [_c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "factor",
      "type": "number",
      "placeholder": "None",
      "label": "Factor",
      "error": _vm.validationErrors.factor
    },
    model: {
      value: _vm.form.factor,
      callback: function ($$v) {
        _vm.$set(_vm.form, "factor", $$v);
      },
      expression: "form.factor"
    }
  })], 1)])]), _c('FormGroup', {
    attrs: {
      "id": "referenceOnly",
      "label": "Reference Only",
      "description": "Select 'Yes' to exclude this reading in consumption calculations, reports and data validation",
      "type": "radio",
      "options": [{
        label: 'Yes',
        value: true
      }, {
        label: 'No',
        value: false
      }],
      "error": _vm.validationErrors.referenceOnly
    },
    model: {
      value: _vm.form.referenceOnly,
      callback: function ($$v) {
        _vm.$set(_vm.form, "referenceOnly", $$v);
      },
      expression: "form.referenceOnly"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "isReset",
      "label": "Meter Reset",
      "description": "Is this the first reading since the meter has been reset/replaced",
      "type": "radio",
      "options": [{
        label: 'Yes',
        value: true
      }, {
        label: 'No',
        value: false
      }],
      "error": _vm.validationErrors.isReset
    },
    model: {
      value: _vm.form.isReset,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isReset", $$v);
      },
      expression: "form.isReset"
    }
  }), _vm.activeContract && ((_vm$activeContract$ra = _vm.activeContract.rates) === null || _vm$activeContract$ra === void 0 ? void 0 : _vm$activeContract$ra.length) > 0 ? _c('FormGroup', {
    attrs: {
      "id": "contractRateType",
      "label": "Contract Rate Name",
      "type": "select",
      "options": _vm.contractRateOptions
    },
    model: {
      value: _vm.form.contractRateType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "contractRateType", $$v);
      },
      expression: "form.contractRateType"
    }
  }) : _vm._e(), _c('label', [_vm._v("Photo")]), _c('FileUpload', {
    staticClass: "mb-2",
    attrs: {
      "value": _vm.file,
      "file-types": [],
      "existing-file-name": _vm.file.filename
    },
    on: {
      "reset": function ($event) {
        return _vm.$emit('fileReset');
      },
      "input": _vm.handleFileInput
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }