<template>
  <div data-cy="asset-accounts-readings-page">
    <TabTitle class="mb-4" icon="fa-input-numeric" action-position="right">
      <template>Readings</template>
      <template #sub-title>View and add readings to this account.</template>
      <template #actions>
        <button data-cy="add-new-btn" class="btn btn-primary" @click.prevent="openFormModal('create')"><i class="fas fa-fw fa-plus"></i> NEW</button>
      </template>
    </TabTitle>
    <div class="bg-lighter rounded-md px-4 py-3 mb-4">
      <div class="d-flex align-items-center">
        <div class="d-flex align-items-center mr-3">
          <strong class="mr-2">Date Range</strong>
          <FormGroup
            id="dateRange"
            v-model="dateRange"
            class="mb-0"
            type="dateRangePicker"
            :config="{ time: true }"
            :disabled="loadingAction.getReadings"
            @input="refresh"
          />
        </div>
        <div class="d-flex align-items-center mr-3">
          <strong class="mr-2">Read Type</strong>
          <FormGroup
            id="search"
            v-model="filters.type"
            class="mb-0"
            type="select"
            :options="[
              { label: 'All', value: '' },
              { label: 'Actual', value: 'A' },
              { label: 'Estimate', value: 'E' },
              { label: 'Customer', value: 'C' },
              { label: 'Direct', value: 'D' },
              { label: 'Automatic', value: 'R' }
            ]"
            :disabled="loadingAction.getReadings"
            @input="refresh"
          />
        </div>
        <div class="d-flex align-items-center mr-3">
          <strong class="mr-2">Source</strong>
          <FormGroup
            id="search"
            v-model="filters.source"
            class="mb-0"
            type="select"
            :options="[
              { label: 'All', value: '' },
              { label: 'Manual', value: 'manual' },
              { label: 'Invoice', value: 'invoice' },
              { label: 'Sub Meter', value: 'sub meter' },
              { label: 'Import', value: 'import' }
            ]"
            :disabled="loadingAction.getReadings"
            @input="refresh"
          />
        </div>
      </div>
    </div>

    <div id="accordion" role="tablist">
      <div
        v-if="selectedAccount.factor !== undefined && selectedAccount.factor !== null && selectedAccount.factor !== 1"
        class="alert alert-info font-w500 mb-2"
      >
        <i class="fa fa-calculator mr-1"></i> This meter has a non-standard factor of {{ selectedAccount.factor }}. Any readings that do not have a
        factor specified below will default to {{ selectedAccount.factor }}.
      </div>
      <div class="font-w600 text-muted mb-3"><i class="fa fa-fw fa-clock text-info"></i> Local timezone</div>
      <div class="d-flex align-items-center mb-2">
        <button class="btn-sort gray-700" style="outline: none" @click="onSort">
          <span class="font-w600 pr-1">Submitted At</span>
          <SortIcon :direction="pagination.sortDirection" :is-sorted="true"></SortIcon>
        </button>
      </div>

      <SpinnerLogo v-if="loadingAction.getReadings">Loading readings...</SpinnerLogo>

      <div v-for="reading in readings" v-else-if="readings.length > 0" :key="reading._id" class="bg-lightest px-4 py-3 rounded-md mb-4">
        <div class="row align-items-center">
          <div class="col-xl-2 col-lg-4 font-size-h5 font-w600">
            <div>{{ reading.submittedAt | date('Do MMM YYYY', null, true) }}</div>
            <div class="text-muted font-size-sm">{{ reading.submittedAt | date('HH:mm:ss') }}</div>
          </div>

          <div class="col-xl-5 col-lg-8">
            <div class="font-w600 mr-2">
              <span v-if="reading.isReset" class="text-danger font-size-sm mr-1">METER RESET</span>{{ reading.value
              }}<ReadType v-if="reading.type?.toUpperCase() !== 'A'" class="ml-1" :read-type="reading.type" />
            </div>
            <small
              >Submitted by <strong>{{ reading.userSub | user(users) }}</strong> on
              <strong>{{ reading.createdAt | date('Do MMM YYYY HH:mm:ss') }}</strong></small
            >
            <div>
              <span class="badge badge-secondary mr-2">{{ reading.units || selectedAccount.meterUnits }}</span>
              <span
                class="badge badge-warning text-capitalize mr-2"
                :class="{ 'badge-info': reading.source === 'manual', 'badge-primary': reading.source === 'invoice' }"
                >{{ reading.source }}</span
              >
              <span v-if="selectedAccount.registerIds.length > 0" class="badge badge-success mr-2">Register {{ reading.registerId || '1' }}</span>
              <span v-if="reading.contractRateType" class="badge text-capitalize badge-info mr-2">{{ reading.contractRateType }}</span>
              <span
                v-if="
                  reading.factor !== undefined &&
                  reading.factor !== null &&
                  ((selectedAccount.factor === 1 && reading.factor !== 1) || selectedAccount.factor !== 1)
                "
                class="badge badge-light mr-2"
                >Factor: {{ reading.factor }}</span
              >
            </div>
          </div>

          <div class="col-xl-5 col-lg-12 text-right mt-xl-0 mt-4">
            <button
              class="btn btn-link font-w600 text-secondary mr-2"
              :disabled="!reading.s3Key || loadingAction.updateReading || loadingAction.getReadings || loadingAction.getPhoto"
              @click.prevent="onClickPhoto(reading)"
            >
              <i class="fa fa-fw fa-camera"></i> {{ loadingAction.getPhoto ? 'Loading...' : 'Photo' }}
            </button>
            <button
              v-if="$permissions.write('account', selectedAccount)"
              class="btn btn-link font-w600 text-secondary mr-2"
              :disabled="loadingAction.updateReading || loadingAction.getReadings"
              @click.prevent="openFormModal('edit', reading)"
            >
              <i class="fa fa-fw fa-pencil"></i> {{ loadingAction.updateReading ? 'Updating...' : 'Edit' }}
            </button>
            <button
              v-if="$permissions.write('account', selectedAccount)"
              class="btn btn-link font-w600 text-danger"
              :disabled="loadingAction.deleteReading || loadingAction.getReadings"
              @click.prevent="modals.delete = reading"
            >
              <i class="fa fa-fw fa-trash"></i> {{ loadingAction.deleteReading === reading._id ? 'Deleting...' : 'Delete' }}
            </button>
          </div>
        </div>
      </div>

      <div v-else class="alert alert-warning" role="alert">
        <p class="mb-0">No readings found within the selected filters.</p>
      </div>
    </div>

    <Pagination
      class="mb-4"
      :current-page="pagination.page"
      :total-pages="pagination.totalPages"
      :loading="loadingAction.getReadings"
      summary
      @change="onChangePage"
    />
    <ConfirmModal
      :open="!!modals.form"
      lg-size
      :title="mode === 'create' ? 'Submit a Reading' : 'Edit a Reading'"
      :loading="modals.loading"
      prevent
      @close="closeFormModal"
      @submit="onClickSubmit"
    >
      <ReadingForm
        v-if="!!modals.form"
        v-model="form"
        :file="file"
        :validation-errors="validationErrors"
        @fileReset="file = {}"
        @fileInput="handleFileInput"
      />
    </ConfirmModal>
    <ConfirmModal
      :open="!!modals.delete"
      title="Delete Reading"
      text="Are you sure you want to delete this reading?"
      :loading="loadingAction.deleteReading"
      @close="modals.delete = false"
      @submit="deleteReading"
    />
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import { downloadFileViaApi } from '@/lib/downloadManager';

import ConfirmModal from '@/components/ConfirmModal';
import FormGroup from '@/components/FormGroup';
import Pagination from '@/components/Pagination';
import SortIcon from '@/components/SortIcon';
import SpinnerLogo from '@/components/SpinnerLogo';
import TabTitle from '@/components/base/TabTitle';
import ReadType from '@/components/ReadType';
import ReadingForm from '@/components/forms/ReadingForm';

export default {
  name: 'AssetAccountReadings',
  components: {
    ConfirmModal,
    FormGroup,
    Pagination,
    ReadingForm,
    ReadType,
    SortIcon,
    SpinnerLogo,
    TabTitle
  },
  data() {
    return {
      modals: {
        delete: false,
        form: false,
        loading: false
      },
      form: {},
      file: {
        filename: null
      },
      filters: {
        type: '',
        source: ''
      },
      mode: 'create',
      pagination: {
        page: 1,
        totalPages: 1,
        perPage: 30,
        sortDirection: -1
      },
      dateRange: [moment().subtract(1, 'year').startOf('day').toDate(), moment().add(1, 'year').endOf('day').toDate()]
    };
  },
  computed: {
    ...mapGetters({
      readings: 'account/readings',
      totalReadings: 'account/totalReadings',
      photoUploadProgress: 'reading/photoUploadProgress',
      selectedAccount: 'asset/selectedAccount',
      loadingAction: 'account/loadingAction',
      errorAction: 'account/errorAction',
      readingErrorAction: 'reading/errorAction',
      users: 'company/users',
      readingSchema: 'reading/schema',
      validationErrors: 'reading/validationErrors'
    })
  },
  async mounted() {
    await Promise.all([this.listUnits(), this.getReadingSchema(), this.listUsers({ id: this.selectedAccount.companyId }), this.refresh()]);
    this.resetForm();
  },
  methods: {
    ...mapActions({
      listReadings: 'account/getReadings',
      uploadPhoto: 'reading/uploadPhoto',
      getPhoto: 'reading/getPhoto',
      submitReading: 'reading/submitReading',
      delete: 'account/deleteReading',
      update: 'reading/editSubmission',
      listUsers: 'company/listUsers',
      getReadingSchema: 'reading/schema',
      listUnits: 'util/listUnits'
    }),
    ...mapMutations({
      clearValidationErrors: 'reading/CLEAR_VALIDATION_ERRORS'
    }),
    handleFileInput(file) {
      this.file = file;
    },
    generateForm() {
      return {
        submittedAt: moment.utc().startOf('day').toDate(),
        value: 0,
        type: 'A',
        units: null,
        registerId: '1',
        referenceOnly: false,
        contractRateType: null,
        search: '',
        isReset: false
      };
    },
    closeFormModal() {
      this.modals.form = false;
      this.resetForm();
    },
    onSort() {
      this.pagination.sortDirection = -this.pagination.sortDirection;
      this.refresh();
    },
    async refresh() {
      const filters = Object.assign(
        {},
        ...Object.entries(this.filters)
          .filter(value => value[1])
          .map(([key, value]) => ({ [key]: value }))
      );

      await this.listReadings({
        data: {
          params: {
            ...filters,
            $sort: `submittedAt:${this.pagination.sortDirection}`,
            $skip: (this.pagination.page - 1) * this.pagination.perPage,
            $limit: this.pagination.perPage,
            accountId: this.selectedAccount._id,
            'submittedAt.gte': moment(this.dateRange[0]).format('YYYY-MM-DD[T]HH:mm'),
            'submittedAt.lte': moment(this.dateRange[1]).format('YYYY-MM-DD[T]HH:mm')
          }
        }
      });

      this.pagination.totalPages = Math.ceil(this.totalReadings / this.pagination.perPage);
    },
    onChangePage(pageNumber) {
      if (pageNumber > this.pagination.totalPages) {
        this.pagination.page = this.pagination.totalPages;
      }

      if (pageNumber < 1) {
        this.pagination.page = 1;
      }

      this.pagination.page = pageNumber;

      this.refresh();
    },
    async deleteReading() {
      const reading = this.modals.delete;

      const update = await this.delete({ id: reading._id });
      if (update) {
        this.$toasted.success('Deleted reading successfully.', { position: 'bottom-center', duration: 3000 });
        this.refresh();
      } else {
        this.$toasted.error('Could not delete reading.', { position: 'bottom-center', duration: 3000 });
      }

      this.modals.delete = false;
    },
    async onClickSubmit() {
      let update = null;
      this.modals.loading = true;

      let photoUpload = null;
      if (this.file.file) {
        photoUpload = await this.uploadPhoto({ data: this.file.file });

        if (!photoUpload.s3Key) {
          this.$toasted.error('Could not upload photo.', { position: 'bottom-center', duration: 3000 });
          this.modals.loading = false;
          return;
        }

        this.form.s3Key = photoUpload.s3Key;
      }

      if (this.mode === 'create') {
        update = await this.submitReading({
          data: {
            ...this.form,
            companyId: this.selectedAccount.companyId,
            entityId: this.selectedAccount.entityId,
            accountId: this.selectedAccount._id
          }
        });
      } else {
        // if no photo upload, no filename in the file, then remove
        if (!this.file?.filename && !photoUpload && this.form.s3Key) {
          this.form.s3Key = null;
        }
        update = await this.update({ id: this.form._id, data: { ...this.form } });
      }

      if (update.id) {
        this.$toasted.success(`${this.mode === 'edit' ? 'Reading successfully edited' : 'Reading was submitted successfully'}`, {
          position: 'bottom-center',
          duration: 3000
        });
        this.refresh();
        this.closeFormModal();
      } else {
        this.$toasted.error(`Could not ${this.mode === 'edit' ? 'edit' : 'submit'} reading.`, { position: 'bottom-center', duration: 3000 });
      }

      this.modals.loading = false;
    },
    openFormModal(mode, reading) {
      this.mode = mode;

      if (reading) {
        console.log('reading', reading);
        if (reading.s3Key) this.file.filename = this.getPhotoName(reading);
        this.form = { ...reading };
      }

      this.modals.form = true;
    },
    getPhotoName(reading) {
      let invoiceName = `${this.selectedAccount.name}`;

      const capitalizedSource = `${reading.source.charAt(0).toUpperCase()}${reading.source.slice(1)}`;
      invoiceName += ` - ${capitalizedSource} - ${moment(reading.submittedAt).format('DDMMYYYY')} - Reading Photo.jpg`;

      return invoiceName;
    },
    async onClickPhoto(reading) {
      const fileName = this.getPhotoName(reading);

      downloadFileViaApi(fileName, this.getPhoto, {
        id: reading._id
      });
    },
    resetForm() {
      this.file = {};
      this.clearValidationErrors();
      this.form = this.generateForm();
    }
  }
};
</script>
<style lang="scss" scoped>
.btn-sort {
  color: #495057;
  background-color: transparent;
  border: none;
}
</style>
