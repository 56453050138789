var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "data-cy": "asset-accounts-readings-page"
    }
  }, [_c('TabTitle', {
    staticClass: "mb-4",
    attrs: {
      "icon": "fa-input-numeric",
      "action-position": "right"
    },
    scopedSlots: _vm._u([{
      key: "sub-title",
      fn: function () {
        return [_vm._v("View and add readings to this account.")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('button', {
          staticClass: "btn btn-primary",
          attrs: {
            "data-cy": "add-new-btn"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.openFormModal('create');
            }
          }
        }, [_c('i', {
          staticClass: "fas fa-fw fa-plus"
        }), _vm._v(" NEW")])];
      },
      proxy: true
    }])
  }, [[_vm._v("Readings")]], 2), _c('div', {
    staticClass: "bg-lighter rounded-md px-4 py-3 mb-4"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mr-3"
  }, [_c('strong', {
    staticClass: "mr-2"
  }, [_vm._v("Date Range")]), _c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "dateRange",
      "type": "dateRangePicker",
      "config": {
        time: true
      },
      "disabled": _vm.loadingAction.getReadings
    },
    on: {
      "input": _vm.refresh
    },
    model: {
      value: _vm.dateRange,
      callback: function ($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mr-3"
  }, [_c('strong', {
    staticClass: "mr-2"
  }, [_vm._v("Read Type")]), _c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "search",
      "type": "select",
      "options": [{
        label: 'All',
        value: ''
      }, {
        label: 'Actual',
        value: 'A'
      }, {
        label: 'Estimate',
        value: 'E'
      }, {
        label: 'Customer',
        value: 'C'
      }, {
        label: 'Direct',
        value: 'D'
      }, {
        label: 'Automatic',
        value: 'R'
      }],
      "disabled": _vm.loadingAction.getReadings
    },
    on: {
      "input": _vm.refresh
    },
    model: {
      value: _vm.filters.type,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "type", $$v);
      },
      expression: "filters.type"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mr-3"
  }, [_c('strong', {
    staticClass: "mr-2"
  }, [_vm._v("Source")]), _c('FormGroup', {
    staticClass: "mb-0",
    attrs: {
      "id": "search",
      "type": "select",
      "options": [{
        label: 'All',
        value: ''
      }, {
        label: 'Manual',
        value: 'manual'
      }, {
        label: 'Invoice',
        value: 'invoice'
      }, {
        label: 'Sub Meter',
        value: 'sub meter'
      }, {
        label: 'Import',
        value: 'import'
      }],
      "disabled": _vm.loadingAction.getReadings
    },
    on: {
      "input": _vm.refresh
    },
    model: {
      value: _vm.filters.source,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "source", $$v);
      },
      expression: "filters.source"
    }
  })], 1)])]), _c('div', {
    attrs: {
      "id": "accordion",
      "role": "tablist"
    }
  }, [_vm.selectedAccount.factor !== undefined && _vm.selectedAccount.factor !== null && _vm.selectedAccount.factor !== 1 ? _c('div', {
    staticClass: "alert alert-info font-w500 mb-2"
  }, [_c('i', {
    staticClass: "fa fa-calculator mr-1"
  }), _vm._v(" This meter has a non-standard factor of " + _vm._s(_vm.selectedAccount.factor) + ". Any readings that do not have a factor specified below will default to " + _vm._s(_vm.selectedAccount.factor) + ". ")]) : _vm._e(), _vm._m(0), _c('div', {
    staticClass: "d-flex align-items-center mb-2"
  }, [_c('button', {
    staticClass: "btn-sort gray-700",
    staticStyle: {
      "outline": "none"
    },
    on: {
      "click": _vm.onSort
    }
  }, [_c('span', {
    staticClass: "font-w600 pr-1"
  }, [_vm._v("Submitted At")]), _c('SortIcon', {
    attrs: {
      "direction": _vm.pagination.sortDirection,
      "is-sorted": true
    }
  })], 1)]), _vm.loadingAction.getReadings ? _c('SpinnerLogo', [_vm._v("Loading readings...")]) : _vm.readings.length > 0 ? _vm._l(_vm.readings, function (reading) {
    var _reading$type;
    return _c('div', {
      key: reading._id,
      staticClass: "bg-lightest px-4 py-3 rounded-md mb-4"
    }, [_c('div', {
      staticClass: "row align-items-center"
    }, [_c('div', {
      staticClass: "col-xl-2 col-lg-4 font-size-h5 font-w600"
    }, [_c('div', [_vm._v(_vm._s(_vm._f("date")(reading.submittedAt, 'Do MMM YYYY', null, true)))]), _c('div', {
      staticClass: "text-muted font-size-sm"
    }, [_vm._v(_vm._s(_vm._f("date")(reading.submittedAt, 'HH:mm:ss')))])]), _c('div', {
      staticClass: "col-xl-5 col-lg-8"
    }, [_c('div', {
      staticClass: "font-w600 mr-2"
    }, [reading.isReset ? _c('span', {
      staticClass: "text-danger font-size-sm mr-1"
    }, [_vm._v("METER RESET")]) : _vm._e(), _vm._v(_vm._s(reading.value)), ((_reading$type = reading.type) === null || _reading$type === void 0 ? void 0 : _reading$type.toUpperCase()) !== 'A' ? _c('ReadType', {
      staticClass: "ml-1",
      attrs: {
        "read-type": reading.type
      }
    }) : _vm._e()], 1), _c('small', [_vm._v("Submitted by "), _c('strong', [_vm._v(_vm._s(_vm._f("user")(reading.userSub, _vm.users)))]), _vm._v(" on "), _c('strong', [_vm._v(_vm._s(_vm._f("date")(reading.createdAt, 'Do MMM YYYY HH:mm:ss')))])]), _c('div', [_c('span', {
      staticClass: "badge badge-secondary mr-2"
    }, [_vm._v(_vm._s(reading.units || _vm.selectedAccount.meterUnits))]), _c('span', {
      staticClass: "badge badge-warning text-capitalize mr-2",
      class: {
        'badge-info': reading.source === 'manual',
        'badge-primary': reading.source === 'invoice'
      }
    }, [_vm._v(_vm._s(reading.source))]), _vm.selectedAccount.registerIds.length > 0 ? _c('span', {
      staticClass: "badge badge-success mr-2"
    }, [_vm._v("Register " + _vm._s(reading.registerId || '1'))]) : _vm._e(), reading.contractRateType ? _c('span', {
      staticClass: "badge text-capitalize badge-info mr-2"
    }, [_vm._v(_vm._s(reading.contractRateType))]) : _vm._e(), reading.factor !== undefined && reading.factor !== null && (_vm.selectedAccount.factor === 1 && reading.factor !== 1 || _vm.selectedAccount.factor !== 1) ? _c('span', {
      staticClass: "badge badge-light mr-2"
    }, [_vm._v("Factor: " + _vm._s(reading.factor))]) : _vm._e()])]), _c('div', {
      staticClass: "col-xl-5 col-lg-12 text-right mt-xl-0 mt-4"
    }, [_c('button', {
      staticClass: "btn btn-link font-w600 text-secondary mr-2",
      attrs: {
        "disabled": !reading.s3Key || _vm.loadingAction.updateReading || _vm.loadingAction.getReadings || _vm.loadingAction.getPhoto
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onClickPhoto(reading);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-camera"
    }), _vm._v(" " + _vm._s(_vm.loadingAction.getPhoto ? 'Loading...' : 'Photo') + " ")]), _vm.$permissions.write('account', _vm.selectedAccount) ? _c('button', {
      staticClass: "btn btn-link font-w600 text-secondary mr-2",
      attrs: {
        "disabled": _vm.loadingAction.updateReading || _vm.loadingAction.getReadings
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.openFormModal('edit', reading);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-pencil"
    }), _vm._v(" " + _vm._s(_vm.loadingAction.updateReading ? 'Updating...' : 'Edit') + " ")]) : _vm._e(), _vm.$permissions.write('account', _vm.selectedAccount) ? _c('button', {
      staticClass: "btn btn-link font-w600 text-danger",
      attrs: {
        "disabled": _vm.loadingAction.deleteReading || _vm.loadingAction.getReadings
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          _vm.modals.delete = reading;
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-trash"
    }), _vm._v(" " + _vm._s(_vm.loadingAction.deleteReading === reading._id ? 'Deleting...' : 'Delete') + " ")]) : _vm._e()])])]);
  }) : _c('div', {
    staticClass: "alert alert-warning",
    attrs: {
      "role": "alert"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v("No readings found within the selected filters.")])])], 2), _c('Pagination', {
    staticClass: "mb-4",
    attrs: {
      "current-page": _vm.pagination.page,
      "total-pages": _vm.pagination.totalPages,
      "loading": _vm.loadingAction.getReadings,
      "summary": ""
    },
    on: {
      "change": _vm.onChangePage
    }
  }), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.modals.form,
      "lg-size": "",
      "title": _vm.mode === 'create' ? 'Submit a Reading' : 'Edit a Reading',
      "loading": _vm.modals.loading,
      "prevent": ""
    },
    on: {
      "close": _vm.closeFormModal,
      "submit": _vm.onClickSubmit
    }
  }, [!!_vm.modals.form ? _c('ReadingForm', {
    attrs: {
      "file": _vm.file,
      "validation-errors": _vm.validationErrors
    },
    on: {
      "fileReset": function ($event) {
        _vm.file = {};
      },
      "fileInput": _vm.handleFileInput
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }) : _vm._e()], 1), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.modals.delete,
      "title": "Delete Reading",
      "text": "Are you sure you want to delete this reading?",
      "loading": _vm.loadingAction.deleteReading
    },
    on: {
      "close": function ($event) {
        _vm.modals.delete = false;
      },
      "submit": _vm.deleteReading
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "font-w600 text-muted mb-3"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-clock text-info"
  }), _vm._v(" Local timezone")]);

}]

export { render, staticRenderFns }