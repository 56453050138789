<template>
  <div class="m-2">
    <FormGroup
      id="date"
      v-model="form.submittedAt"
      class="mb-1"
      label="Date"
      description="Date above is in your local timezone"
      type="dateTimePicker"
      :config="{ utc: false }"
      :error="validationErrors.submittedAt"
    />
    <FormGroup id="value" v-model="form.value" label="Value" type="text" :error="validationErrors.value" />

    <FormGroup id="type" v-model="form.type" label="Type" type="select" :options="typeOptions" :error="validationErrors.type" />

    <FormGroup
      v-if="registerOptions.length > 1"
      id="registerId"
      v-model="form.registerId"
      label="Register"
      type="select"
      :options="registerOptions"
      :error="validationErrors.registerId"
    />

    <div class="mb-3 bg-light p-3 rounded-md">
      <div class="font-w600 mb-1">Meter Overrides</div>
      <div class="font-size-sm font-w600 text-muted mb-3">
        By default the meter's units and factors will be used. Only override these if you are aware of the implications this may have.
      </div>
      <div class="row mb-2">
        <div class="col">
          <FormGroup
            id="units"
            v-model="form.units"
            :placeholder="`Default (${selectedAccount.meterUnits})`"
            label="Units"
            type="select"
            class="mb-0"
            :options="unitOptions"
            :error="validationErrors.units"
            :config="{ allowClear: true }"
            style="width: 100%"
          />
        </div>
        <div class="col-md-5">
          <FormGroup
            id="factor"
            v-model="form.factor"
            type="number"
            class="mb-0"
            placeholder="None"
            label="Factor"
            :error="validationErrors.factor"
          />
        </div>
      </div>
    </div>

    <FormGroup
      id="referenceOnly"
      v-model="form.referenceOnly"
      label="Reference Only"
      description="Select 'Yes' to exclude this reading in consumption calculations, reports and data validation"
      type="radio"
      :options="[
        { label: 'Yes', value: true },
        { label: 'No', value: false }
      ]"
      :error="validationErrors.referenceOnly"
    />

    <FormGroup
      id="isReset"
      v-model="form.isReset"
      label="Meter Reset"
      description="Is this the first reading since the meter has been reset/replaced"
      type="radio"
      :options="[
        { label: 'Yes', value: true },
        { label: 'No', value: false }
      ]"
      :error="validationErrors.isReset"
    />

    <FormGroup
      v-if="activeContract && activeContract.rates?.length > 0"
      id="contractRateType"
      v-model="form.contractRateType"
      label="Contract Rate Name"
      type="select"
      :options="contractRateOptions"
    />

    <label>Photo</label>
    <FileUpload
      :value="file"
      class="mb-2"
      :file-types="[]"
      :existing-file-name="file.filename"
      @reset="$emit('fileReset')"
      @input="handleFileInput"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import FileUpload from '@/components/FileUpload';
import Form from '@/components/forms/Form';
import FormGroup from '@/components/FormGroup';

export default {
  name: 'ReadingForm',
  components: {
    FileUpload,
    FormGroup
  },
  extends: Form,
  props: {
    file: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      typeOptions: [
        { label: 'Actual', value: 'A' },
        { label: 'Estimate', value: 'E' },
        { label: 'Customer', value: 'C' },
        { label: 'Direct', value: 'D' },
        { label: 'Automatic', value: 'R' }
      ]
    };
  },
  computed: {
    ...mapGetters({
      selectedAccount: 'asset/selectedAccount',
      units: 'util/units',
      validationErrors: 'reading/validationErrors'
    }),
    activeContract() {
      return this.selectedAccount.contracts?.length ? this.selectedAccount.contracts.find(contract => contract.status === 'active') : {};
    },
    contractRateOptions() {
      return [{ label: '-', value: null }, ...(this.activeContract?.rates || []).map(rate => ({ label: rate.type, value: rate.type }))];
    },
    registerOptions() {
      return [
        { label: '1 (Default)', value: '1' },
        ...(this.selectedAccount.registerIds || []).map(registerId => ({ label: registerId, value: registerId }))
      ];
    },
    unitOptions() {
      return [...this.units.filter(u => u.types.includes(this.selectedAccount.type)).map(u => ({ label: u.name, value: u.value }))];
    }
  },
  methods: {
    handleFileInput(file) {
      this.$emit('fileInput', file);
    }
  }
};
</script>
